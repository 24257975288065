var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sub-page',{attrs:{"title":_vm.$t('documentflow.for_signature'),"no-content-condition":_vm.itemsFiltered.length === 0,"no-content-text":("documentflow.signature." + (_vm.$store.state.documentflow.displaySigned ? 'no_signed_content' : 'no_content')),"no-content-icon":_vm.$store.state.documentflow.displaySigned ? 'mdi-file-document-multiple-outline' : 'mdi-draw',"icon":"mdi-draw","loading":_vm.loading,"fill-height":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [(_vm.itemsFiltered.length > 0)?_c('ws-data-table',{staticClass:"mt-5",attrs:{"items":_vm.itemsFiltered,"headers":_vm.headers,"row-action":function ($event) { return _vm.openEditDocument($event); }},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.MONTH_DAY_TIME(item.date , false)))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('h5',{style:(("color : " + _vm.wsACCENT))},[_vm._v(_vm._s(item.name)+" "+_vm._s(item.is_overdue)+" "),(item.is_overdue)?_c('v-chip',{attrs:{"color":_vm.wsATTENTION,"small":"","dark":""}},[_vm._v(_vm._s(_vm.$t('documentflow.signature.overdued')))]):_vm._e()],1)]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.code))])]}},{key:"item.is_signed",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('v-icon',{attrs:{"color":item.is_signed ? _vm.wsACCENT : null}},[_vm._v("mdi-check-decagram")])],1)]}}],null,false,3121639124)}):_vm._e()]},proxy:true},{key:"dialog",fn:function(){return [(_vm.displayDialog)?_c('document-dialog',{attrs:{"document":_vm.entityData,"for-sign":""},on:{"sign":_vm.signDocument,"decline":_vm.declineDocument,"upload-extra":_vm.uploadExtraFile},model:{value:(_vm.displayDialog),callback:function ($$v) {_vm.displayDialog=$$v},expression:"displayDialog"}}):_vm._e()]},proxy:true}]),model:{value:(_vm.$store.state.settings.pageData),callback:function ($$v) {_vm.$set(_vm.$store.state.settings, "pageData", $$v)},expression:"$store.state.settings.pageData"}})}
var staticRenderFns = []

export { render, staticRenderFns }